<template>
  <div class="text-center">
    <section-status status="0"></section-status>
    <section-status status="1"></section-status>
    <section-status status="2"></section-status>
  </div>
</template>
<script>
import SectionStatus from '../../components/atomic/molecules/SectionStatus';

export default {
  components: {
    SectionStatus
  },
  computed: {
  },
  methods: {

  },
}
</script>